// App.js

import "./App.css";
import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navigation from "./components/Navigation";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProductDetail from "./components/ProductDetail";
import CancellationRefundPolicy from "./components/CancellationRefundPolicy";
import TermsConditions from "./components/TermsConditions";
import React from "react";

function App() {
  return (
    <Router>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<ProductDetail />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<CancellationRefundPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

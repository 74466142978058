import "./PrivacyPolicy.css"; // Specific CSS for PrivacyPolicy

import React from "react";

// Define labels for the content
const privacyPolicyContent = {
  title: "Privacy Policy",
  intro: "At TechKarmic Ventures, we are committed to protecting your privacy. This policy outlines how we handle your personal information.",
  dataCollection: {
    title: "Data Collection",
    content: "We collect personal identification information such as your name, email address, and phone number when you use our services or contact us.",
  },
  purposeOfDataCollection: {
    title: "Purpose of Data Collection",
    content: "Your data helps us provide personalized tech support services, respond to inquiries, and improve our offerings.",
  },
  dataStorageAndSecurity: {
    title: "Data Storage and Security",
    content: "We store your data securely and take all necessary measures to protect it from unauthorized access, disclosure, or destruction.",
  },
  paymentInformation: {
    title: "Payment Information",
    content: [
      "When you make a payment for our services, we may collect and process certain payment-related information, including payment method details, billing information, and transaction records.",
      "We collect and process payment-related information solely for the purpose of processing payments for the services you have requested and to maintain accurate financial records.",
      "Your payment information is securely stored and used to facilitate transactions securely.",
      "To process payments, we may engage third-party payment processors. These processors have their own privacy policies and security measures in place. We encourage you to review their policies to understand how they handle your payment information.",
      "We retain payment information only for as long as necessary to complete the transaction, comply with legal obligations, and maintain financial records. After this period, we securely dispose of or anonymize your payment information."
    ],
  },
  yourRights: {
    title: "Your Rights",
    content: "You have the right to request access to your payment information, rectify any inaccuracies, or request the deletion of this information when it is no longer required for legal or legitimate business purposes. Please contact us using the information provided in the 'Contact Us' section of this privacy policy to exercise your rights.",
  },
  contactUs: {
    title: "Contact Us",
    content: "If you have any questions or concerns about our privacy practices, including payment-related matters, please contact us at rutwik@techkarmic.com.",
  },
};

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container" id="privacy-policy">
      <h2>{privacyPolicyContent.title}</h2>
      <p>{privacyPolicyContent.intro}</p>

      {/* Data Collection */}
      <h3>{privacyPolicyContent.dataCollection.title}</h3>
      <p>{privacyPolicyContent.dataCollection.content}</p>

      {/* Purpose of Data Collection */}
      <h3>{privacyPolicyContent.purposeOfDataCollection.title}</h3>
      <p>{privacyPolicyContent.purposeOfDataCollection.content}</p>

      {/* Data Storage and Security */}
      <h3>{privacyPolicyContent.dataStorageAndSecurity.title}</h3>
      <p>{privacyPolicyContent.dataStorageAndSecurity.content}</p>

      {/* Payment Information */}
      <h3>{privacyPolicyContent.paymentInformation.title}</h3>
      <ul>
        {privacyPolicyContent.paymentInformation.content.map((point, index) => (
          <li key={index}>{point}</li>
        ))}
      </ul>

      {/* Your Rights */}
      <h3>{privacyPolicyContent.yourRights.title}</h3>
      <p>{privacyPolicyContent.yourRights.content}</p>

      {/* Contact Us */}
      <h3>{privacyPolicyContent.contactUs.title}</h3>
      <p>{privacyPolicyContent.contactUs.content}</p>
    </div>
  );
};

export default PrivacyPolicy;

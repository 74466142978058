import React, { useState } from 'react';
import { Link } from "react-router-dom";
import "./Navigation.css";

const Navigation = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-logo">
          TechKarmic Ventures
        </Link>
        <button className="menu-icon" onClick={toggleMobileMenu}>
          &#9776; {/* Hamburger Icon */}
        </button>
        <ul className={`nav-menu ${mobileMenuOpen ? 'active' : ''}`}>
          <li className="nav-item">
            <Link to="/" className="nav-links" onClick={() => setMobileMenuOpen(false)}>
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/privacy-policy" className="nav-links" onClick={() => setMobileMenuOpen(false)}>
              Privacy Policy
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/refund-policy" className="nav-links" onClick={() => setMobileMenuOpen(false)}>
              Refund And Cancellation Policy
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/terms-conditions" className="nav-links" onClick={() => setMobileMenuOpen(false)}>
              Terms and Conditions
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;

import "./Contact.css"; // Specific CSS for Contact

// components/Contact.js
import React from "react";

const Contact = () => {
  return (
    <div className="contact-container" id="contact">
      <h2>Contact Us</h2>
      <p>
        If you have any questions or need assistance, feel free to reach out to
        us.
      </p>
      <div className="contact-info">
        <p>
          Email:{" "}
          <a href="mailto:rutwik@techkarmic.com">rutwik@techkarmic.com</a>
        </p>
        <p>Phone: +919313138255</p>
        <p>
          <a
            href="https://wa.me/message/SAH7QBSXQS2MI1"
            className="whatsapp-link"
          >
            Message on WhatsApp
          </a>
        </p>
      </div>
    </div>
  );
};

export default Contact;

import "./CancellationRefundPolicy.css"; // Specific CSS for CancellationRefundPolicy

import React from "react";

// Define labels for the content
const cancellationRefundPolicyContent = {
  title: "Cancellation and Refund Policy",
  intro: "At TechKarmic Ventures, we value your satisfaction. However, due to the nature of our digital services, there are specific conditions regarding cancellations and refunds.",
  noRefundPolicy: {
    title: "No Refund Policy",
    content: "All purchases of credits for use within our chat app are final. We do not offer refunds for any purchased credits, as they are instantly applied to your account for immediate use.",
  },
  accountCancellation: {
    title: "Account Cancellation",
    content: "You may cancel your account at any time. Account cancellation will prevent future charges but will not result in a refund for any remaining credits or previously incurred charges.",
  },
  exceptions: {
    title: "Exceptions",
    content: "In exceptional cases, such as duplicate charges due to technical errors, we may issue refunds. Such cases will be examined on an individual basis, and decisions are at the discretion of TechKarmic Ventures.",
  },
  contactUs: {
    title: "Contact Us",
    content: "For any inquiries regarding our cancellation and refund policy, please contact us at rutwik@techkarmic.com.",
  },
};

const CancellationRefundPolicy = () => {
  return (
    <div className="cancellation-refund-policy-container" id="cancellation-refund-policy">
      <h2>{cancellationRefundPolicyContent.title}</h2>
      <p>{cancellationRefundPolicyContent.intro}</p>

      {/* No Refund Policy */}
      <h3>{cancellationRefundPolicyContent.noRefundPolicy.title}</h3>
      <p>{cancellationRefundPolicyContent.noRefundPolicy.content}</p>

      {/* Account Cancellation */}
      <h3>{cancellationRefundPolicyContent.accountCancellation.title}</h3>
      <p>{cancellationRefundPolicyContent.accountCancellation.content}</p>

      {/* Exceptions */}
      <h3>{cancellationRefundPolicyContent.exceptions.title}</h3>
      <p>{cancellationRefundPolicyContent.exceptions.content}</p>

      {/* Contact Us */}
      <h3>{cancellationRefundPolicyContent.contactUs.title}</h3>
      <p>{cancellationRefundPolicyContent.contactUs.content}</p>
    </div>
  );
};

export default CancellationRefundPolicy;

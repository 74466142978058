import "./TermsConditions.css"; // Specific CSS for TermsConditions

import React from "react";

// Define labels for the content
const termsConditionsContent = {
  title: "Terms and Conditions",
  intro: "Welcome to our chat app! These terms and conditions outline the rules and regulations for the use of TechKarmic Ventures' Chat App, available at techkarmic.com.",
  useOfService: {
    title: "Use of Service",
    content: "Our service allows you to communicate with chatbots for assistance, entertainment, or educational purposes. Charges apply based on the amount of credits you purchase and use within the app.",
  },
  purchaseOfCredits: {
    title: "Purchase of Credits",
    content: "Credits can be purchased through our app and are required to use our service. Credits are non-refundable and must be used within the app.",
  },
  prohibitedUse: {
    title: "Prohibited Use",
    content: "You agree not to use our service for any unlawful purpose, to spread malware, or to engage in any other activity that could damage, disable, overburden, or impair the service.",
  },
  termination: {
    title: "Termination",
    content: "We reserve the right to terminate or suspend access to our service immediately, without prior notice or liability, for any reason, including breach of Terms and Conditions.",
  },
  contactUs: {
    title: "Contact Us",
    content: "Should you have any questions about these Terms and Conditions, please contact us at rutwik@techkarmic.com.",
  },
};

const TermsConditions = () => {
  return (
    <div className="terms-conditions-container" id="terms-conditions">
      <h2>{termsConditionsContent.title}</h2>
      <p>{termsConditionsContent.intro}</p>

      {/* Use of Service */}
      <h3>{termsConditionsContent.useOfService.title}</h3>
      <p>{termsConditionsContent.useOfService.content}</p>

      {/* Purchase of Credits */}
      <h3>{termsConditionsContent.purchaseOfCredits.title}</h3>
      <p>{termsConditionsContent.purchaseOfCredits.content}</p>

      {/* Prohibited Use */}
      <h3>{termsConditionsContent.prohibitedUse.title}</h3>
      <p>{termsConditionsContent.prohibitedUse.content}</p>

      {/* Termination */}
      <h3>{termsConditionsContent.termination.title}</h3>
      <p>{termsConditionsContent.termination.content}</p>

      {/* Contact Us */}
      <h3>{termsConditionsContent.contactUs.title}</h3>
      <p>{termsConditionsContent.contactUs.content}</p>
    </div>
  );
};

export default TermsConditions;

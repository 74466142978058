import "./HeroSection.css"; // Specific CSS for HeroSection

// components/HeroSection.js
import React from "react";

const HeroSection = () => {
  return (
    <div className="hero-container">
      <h1>Innovative Tech Solutions</h1>
      <p>Empowering Your Business with Cutting-Edge Technology</p>
      <a href="#services" className="hero-btn">
        Explore Our Services
      </a>
    </div>
  );
};

export default HeroSection;

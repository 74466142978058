import Contact from "./Contact";
import HeroSection from "./HeroSection";
import React from "react";
import Services from "./Services";

const Home = () => {
  return (
    <div>
      <HeroSection />
      <Services />
      <Contact />
    </div>
  );
};

export default Home;

import "./Services.css"; // Specific CSS for Services

// components/Services.js
import React from "react";

const Services = () => {
  return (
    <div className="services-container" id="services">
      <h2>Our Services</h2>
      <div className="services-grid">
        <div className="service">
          <h3>Backend Development</h3>
          <p>
            Specializing in Django and Node.js to build robust backend
            solutions.
          </p>
        </div>
        <div className="service">
          <h3>Cloud Infrastructure</h3>
          <p>
            Expertise in AWS for scalable and secure cloud-based applications.
          </p>
        </div>
        <div className="service">
          <h3>Database Management</h3>
          <p>
            Managing efficient data storage solutions with MongoDB and
            PostgreSQL.
          </p>
        </div>
        <div className="service">
          <h3>Chatbot Integration</h3>
          <p>
            Incorporating intelligent chatbot solutions to enhance user
            interaction.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;

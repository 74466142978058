import "./Footer.css"; // Specific CSS for Footer

// components/Footer.js
import React from "react";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <p>Connect with us:</p>
        <a href="mailto:rutwik@techkarmic.com" className="footer-link">
          Email
        </a>
        <a href="tel:+919313138255" className="footer-link">
          Phone
        </a>
      </div>
      <div className="footer-bottom">
        <p>© 2024 TechKarmic Ventures. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
